@import 'variables';

body {
  font-family: 'Open Sans', sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.logo{
  cursor: pointer;
  padding-top: 5px;
}
.nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 20px;
  background-color: #f9f9f9;
}
.landing-header{
  display: flex;
  /* padding-top: 10%; */
  flex-direction: column;
  max-width: 60%;
  margin-left: 10vw;
  text-align: left;
  align-items: start;
}

.landing-header h1{
  font-size: 4rem;
  span{
    color:rgb(184, 189, 192);

  }
}
.landing-header p{
  font-size: 1.5rem;
  color:rgb(90, 93, 95);
  font-weight:bold;
}

h4{
  font-size: 1.5rem;
  color: #8b8b8b;
  font-weight:bold;
}

h5{
  font-size: 1.3rem;
  color:rgb(90, 93, 95);
  font-weight:bold;
}

.grid {
  display: grid;
  &.auto-1 {
    grid-template-columns: auto 1fr;
  }
  &.fr-2 {
    grid-template-columns: 1fr 1fr;
    @media #{$bp-mobile} {
      grid-template-columns: 1fr;
    }
    @media #{$bp-tablet} {
      grid-template-columns: 1fr;
    }
  }
  &.fr-3 {
    grid-template-columns: 1fr 1fr 1fr;
    @media #{$bp-mobile} {
      grid-template-columns: 1fr;
    }
    @media #{$bp-tablet} {
      grid-template-columns: 1fr;
    }
  }
  &.fr-1-2 {
    grid-template-columns: 1fr 2fr;
    .idea-info {
      margin-right: 20px;
    }
    @media #{$bp-mobile} {
      grid-template-columns: 1fr;
    }
    @media #{$bp-tablet} {
      grid-template-columns: 1fr;
    }
  }
  &.fr-2-1 {
    grid-template-columns: 2fr 1fr;
    .idea-info {
      margin-left: 20px;
    }
    @media #{$bp-mobile} {
      grid-template-columns: 1fr;
    }
    @media #{$bp-tablet} {
      grid-template-columns: 1fr;
    }
  }
}

.hamburger-react {
  display: none;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.nav-links .li {
  margin: 0 15px;
}

.nav-links a {
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.section {
  padding: 60px 20px;

  &.contact {
    padding-bottom: 0 !important;
  }

  &.landing {
    padding: 100px 20px;
  }

  &.min {
    min-height: 74vh;
  }
}

.section:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.section:nth-of-type(even) {
  background-color: #e9e9e9;
}

h1 {
  margin: 0;
  padding: 20px 0;
}

.landing{
  justify-content: space-around;
  align-items: center;
}

// .landing-header{
//   display: flex;
//   align-items: center;
//   flex-direction: column;
// }



.landing-header button{
  padding: 10px 20px;
  background-color: #069494;
  border: none;
  border-radius: 7px;
  color: white;

  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.landing-image{
  border-radius: 100px;
  width: 450px;
  height: 450px;
  background-color: rgba(6, 148, 148, 0.2);
  display: flex;
  justify-content: right;
}

@keyframes slide-from-top{
  0%{
    transform: translateY(20%);
  }
  100%{
    transform: translateY(0%);
  }
}
@keyframes slide-from-left{
  0%{
    transform: translateX(20%);
  }
  100%{
    transform: translateX(0%);
  }
}
@keyframes slide-from-right{
  0%{
    transform: translateX(-10%);
  }
  100%{
    transform: translateX(0%);
  }
}

.landing-image-container{
  display: flex;
  justify-content: center;
}

.landing-image-monitor{
  width: 270px;
  height: 270px;
  background-image: url('../src/img/monitor.png');
  background-size:cover;
  position:absolute;
  margin-top: -45px;
  margin-right: -70px;
  opacity: 0.7;
  animation: 0.5s ease-out 0s 1 slide-from-left;
}

.landing-image-coaching{
  width: 250px;
  height: 250px;
  background-image: url('../src/img/coaching.png');
  background-size:cover;
  position:absolute;
  margin-top: 80px;
  margin-right: 250px;
  animation: 0.7s ease-out 0s 1 slide-from-right;

}
.landing-image-pie{
  width: 250px;
  height: 250px;
  background-image: url('../src/img/pie.png');
  background-size:cover;
  position:absolute;
  margin-top: 270px;
  margin-right: 80px;
  opacity: 0.8;
  animation: 1s ease-out 0s 1 slide-from-left;
}

.idea-container{
  display: flex;
  flex-direction: column;
  margin: 0 10%;
  padding: 5vh;
  background-color: #069494;
  border-radius: 20px;
  color: #f9f9f9;
  align-items: center;
  position: relative;
}

.idea-item {
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, margin 0.5s ease;
  height: 0;
  margin: 0;

  .idea-info {
    align-self: baseline;
  }
}

.idea-item.active {
  opacity: 1;
  visibility: visible;
  height: auto;
  min-height: 450px;
}

.idea-image{
  height: 110%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.product1{
  background-image: url('img/product1.png');
}
.product2{
  background-image: url('img/product2.png');
}
.product3{
  background-image: url('img/product3.png');
}
.product4{
  background-image: url('img/product4.png');
}

.idea-info{
  display: flex;
  flex-direction: column;
  //text-align: left;
  align-items: start;
  margin: 10px;
}

.idea-left, .idea-right{
  width: 70px;
  height: 70px;
  background-color: rgb(223, 201, 174);
  box-shadow: 20px 20px 20px rgba(0,0,0,0.1);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.idea-left {
  left: 80px;
}

.idea-right {
  right: 80px;
}

.idea-left{
  position: absolute;
  margin-left: -130px;
}
.idea-right{
  margin-right: -130px;
}

.roadmap-container{
  display: flex;
  justify-content: space-around;
  padding-top: 5vh;
}

.roadmap-heading{
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 40%;
  text-align: left;
  height: 100%;
}

.roadmap-heading h1{
  font-size: 4rem;
  padding-bottom: 20px;
}

.roadmap-heading p{
  font-size: 1.5rem;
}

.roadmap-steps-container{
  max-width: 40%;
  justify-content: center;
}

.roadmap-steps{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  background-color: #069494;
  border-color: #e9e9e9;
}

.roadmap-step{
  display: flex;
  align-self: flex-start;
  width: 80%;
}

.roadmap-step-num{
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.3);
  color: rgb(78, 145, 172);
  margin-top: 30px;
  margin-left: -20px;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
}

.completed-step-num {
  background-color: #e0ffe0;
}

.progress-step-num {
  background-color: #fcf5d6;
}

.roadmap-step-num h3{
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
}

.roadmap-step-info{
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #f9f9f9;
}

.roadmap-step-info h3{
  font-size: 1.5rem;
  margin-bottom: 0;
}

.roadmap-step-info p{
  font-size: 0.8rem;
}

.team-container{
  justify-items: center;
}

.team h1{
  margin-bottom: 5vh;
}

.team-item{
  height: 400px;
  width: 300px;
  background-color: #f9f9f9;
  border-radius: 20px;
  box-shadow: 20px 20px 20px rgba(0,0,0,0.1);
  margin: 0 15%;
  transition: box-shadow 0.2s ease-in-out, box-shadow 0.8s ease-in-out 1s;
}

.member-image {
  background-size: cover;
  margin-bottom: 10px;
}

#dali-image{
  background-image: url('../src/img/di.png');
  background-size: cover;
}

#ilija-image{
  background-image: url('../src/img/ii.png');
  background-size: cover;
}

#milan-image{
  background-image: url('../src/img/mi.png');
  background-size: cover;
}

.team-item-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s ease;
}

.team-item:hover {
  box-shadow: none;
  transition: box-shadow 0s ease-in-out;

  .member-info-overlay {
    opacity: 1;
    visibility: visible;
  }

  .team-item-inner{
    transform: rotateY(180deg);

    .member-image, .member-info {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.4s ease, visibility 0.4s ease;
    }
  }
}

.team-item:not(:hover) {
  box-shadow: 20px 20px 20px rgba(0,0,0,0.1);
  transition-delay: 0.1s;

  .member-image, .member-info {
    transition: opacity 0.6s ease-in, visibility 0.6s ease-in;
  }
  .member-info-overlay {
    visibility: hidden;
  }
}

.member-image{
  width: 100%;
  height: 70%;
  border-radius: 20px 20px 0 0;
  background-color:bisque;
}

.member-info{
  height: 30%;
  //padding: 10px;
  text-align: center;
  h3 {
    margin: 8px;
  }

  p {
    margin: 0;
  }
}

.team-item {
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 300px;
  background-color: #f9f9f9;
  border-radius: 20px;
  box-shadow: 20px 20px 20px rgba(0,0,0,0.1);
  margin: 0 15%;
}

.member-image {
  width: 100%;
  height: 70%;
  border-radius: 20px 20px 0 0;
  background-color: bisque;
  transition: filter 0.3s ease;
  position: relative;
  z-index: 1;
}

.team-item:hover .member-image {
  filter: blur(5px);
}

.member-info-overlay {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #069494;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  border-radius: 20px 20px 0 0;
  z-index: 2;
  transition: opacity 0.6s ease-in, visibility 0.2s;
}

.member-details {
  font-size: 18px;
  font-weight: bold;
  //color: skyblue;
  margin: 10px;
}


.join-us{
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.join-us h1{
  font-size: 1.5rem;
}

.join-us button{
  padding: 10px 20px;
  background-color: #069494;
  border: none;
  border-radius: 15px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.contact-form-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vh;

}
.contact-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  padding: 3vh;
  background-color: #e9e9e9;
  border-radius: 30px;
  width: 40vw;
}

.contact-form input{
  border-radius: 20px;
  border: 1px solid #f9f9f9;
  margin: 10px;
  padding: 10px;
  min-height: 25px;
}

textarea {
  border-radius: 20px;
  border: 1px solid #f9f9f9;
  margin: 10px;
  padding: 10px;
  resize: none;
}


.contact-form button{
  padding: 10px 20px;
  background-color: #069494;
  border: none;
  border-radius: 15px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  align-self: center;
  cursor: pointer;
  margin-top: 20px;
}


@media #{$bp-tablet} {
  .landing-image {
    margin-top: 50px;
  }
  .landing-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    // margin-left: none;
    align-self: center;
    margin: auto;
    margin-bottom: 5vh;
  }
  .landing h4 {
    text-align: center;
  }

  .team-item {
    margin-bottom: 5vh !important;
  }

  .idea-item {
    .idea-image {
      //width: 100% !important;
      height: 50vh !important;
    }
  }

  .hamburger-react {
    display: block;
  }

  .nav-links {
    transition: visibility 0.5s, opacity 0.5s linear;
    visibility: hidden;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    opacity: 0;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
  }

  .nav-links.open {
    visibility: visible;
    opacity: 1;

    :hover {
      background-color: #ddd;
    }
  }

  .nav-links .li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    margin: 0 !important;
    text-align: center;
  }

  .dropdown-button {
    margin-right: 10px;
  }
}

@media #{$bp-mobile} {
  .logo img {
    width: 70%;
  }

  .idea-image {
    display: none;
  }

  .content-wrapper {
    display: unset !important;

    .vision-content, .mission-content {
      text-align: center;
      padding: 0 20px
    }
  }

  .content-wrapper::before {
    display: none;
  }

  .nav {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .landing-header{
    display: flex;
    align-items: center;
    flex-direction: column;
    // margin-left: none;
    align-self: center;
    margin: auto;
    max-width: 80%;
    margin-bottom: 5vh;
  }
  .landing h1{
    font-size: 3rem;
  }
  .landing h4{
    // font-size: 1.4rem;
    text-align: center;
  }

  .idea h4{
    margin: 10%;
  }

  .roadmap {
    max-width: 85%;
    align-self: center;
    margin: auto;
  }

  .roadmap h4{
    padding-bottom: 3vh;
  }

  .landing-image{
    width: 250px !important;
    height: 250px !important;
    margin-top: 50px;

    .landing-image-monitor{
      width: 180px !important;
      height: 180px !important;
      animation: 0.5s ease-out 0s 1 slide-from-top !important;
    }

    .landing-image-coaching{
      width: 170px !important;
      height: 170px !important;
      margin-top: 30px !important;
      margin-right: 130px !important;
    }

    .landing-image-pie{
      width: 170px !important;
      height: 170px !important;
      margin-top: 170px !important;
      margin-right: 30px !important;
    }
  }


  .team-item{
    margin-bottom: 5vh !important;
  }

  .join-us{
    padding-top: 0;
  }

  .contact-form{
    width: 70vw;
  }

  .hamburger-react {
    display: block;
  }

  .nav-links {
    transition: visibility 0.5s, opacity 0.5s linear;
    visibility: hidden;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    opacity: 0;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
  }

  .nav-links.open {
    visibility: visible;
    opacity: 1;

    :hover {
      background-color: #ddd;
    }
  }

  .nav-links .li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    margin: 0 !important;
    text-align: center;
  }

  .roadmap-container{
    display: grid !important;
    grid-template-columns: 1fr;

    .roadmap-steps-container{
      max-width: 100% !important;
      margin-left: 10px !important;
    }
    .roadmap-heading{
      max-width: unset !important;
      position: unset !important;
    }
  }
}

.top-content {
  display: flex;
  justify-content: right;
  align-items: center;
  color: white;
  gap: 32px;
}

.dropdown-button {
  cursor: pointer;
  color: black;
  padding: 0 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-left: 30px;
}

.dropdown-button:after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  //padding: 8px 0;
  margin-top: 4px;
  margin-right: 8px;
  z-index: 1;
  white-space: nowrap; /* Ovaj stil osigurava da se meni otvara samo koliko je potrebno za tekst */
}

.dropdown-item {
  padding: 8px 16px;
  color: black;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.footer {
  background-color: #f8f8f8;
  padding: 20px;
  border-top: 1px solid #ccc;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.footer-email {
  color: black;
  text-decoration: none;
}

.footer-right {
  display: flex;
  gap: 15px;
}

.footer-link {
  color: black;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.accordion {
  //max-width: 600px;
  margin: 20px auto;
  border: 1px solid #ccc;
  //border-radius: 8px;
}

.accordion-item {
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordion-item:last-child {
  border-bottom: none;
}

.accordion-header {
  background-color: #f1f1f1;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.accordion-header:hover {
  background-color: #e1e1e1;
}

.accordion-content {
  //max-height: 0;
  overflow: hidden;
  background-color: white;
  padding: 0 15px;
  transition: max-height 0.5s ease, padding 0.3s ease; /* Glatka tranzicija za max-height i padding */
}

.accordion-content p {
  padding: 15px 0;
  margin: 0;
}

.accordion-item.active .accordion-content {
  padding: 15px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: auto;
}

.vision-content {
  flex: 1;
  text-align: right;
}
.mission-content {
  flex: 1;
  text-align: left;
}

.vision-content {
  padding-top: 5%;
  padding-left: 30px;
  padding-right: 50px;
}

.mission-content {
  padding-top: 13%;
  padding-left: 50px;
  padding-right: 30px;
}

.content-wrapper::before {
  content: '';
  width: 4px;
  height: 100%;
  background-color: #8b8b8b;
  position: absolute;
  left: 50%;
}

.vision {
  h4{
    font-size: 1.5rem;
  }

  h1 {
    font-size: 2.5rem;
  }
}

.partners-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px;
  background-color: #f5f5f5;
}

.partners-track {
  display: flex;
  //gap: 85px;
  animation: scroll-left 25s linear infinite;
  animation-play-state: running;
  height: 100%;
  width: max-content;
  background-color: #e9e9e9;
}

.partners-container:hover .partners-track {
  animation-play-state: paused;
}

.partner-item {
  flex: none;
  transition: transform 0.3s ease;
  padding: 0 60px;
}

.partner-item:hover .partner-logo {
  transform: scale(1.1);
}

.partner-logo {
  height: 100%;
  max-height: 200px;
  transition: transform 0.3s ease;
  margin-top: 100px;
}

@keyframes scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-50%));
  }
}

.height-100 {
  height: 100px !important;
}

.height-150 {
  height: 150px !important;
}

.mt-125 {
  margin-top: 125px !important;
}

.mt-150 {
  margin-top: 150px !important;
}